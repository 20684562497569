import React, { memo } from 'react';
import * as styles from './Preview.module.css';
import Artboard from '../builder/center/Artboard';
// import LeftSidebar from '../builder/left/LeftSidebar';
import RightSidebar from '../builder/right/RightSidebar';

const Preview = () => (
  <div className={styles.container}>
    {/* <div className={styles.left}>
      <LeftSidebar />
    </div> */}
    <div className={styles.center}>
      <Artboard />
    </div>
    <div className={styles.right}>
      <RightSidebar />
    </div>
  </div>
);

export default memo(Preview);
