import React, { memo, useState } from 'react';
import Multistep from 'react-multistep';
import Select from 'react-dropdown-select';
import { FaRegFilePdf } from 'react-icons/fa';
import * as styles from './WizardPanel.module.css';
import { useSelector } from '../../contexts/ResumeContext';
import Education from '../builder/left/sections/Education';
import Objective from '../builder/left/sections/Objective';
import Profile from '../builder/left/sections/Profile';
import Skills from '../builder/left/sections/Skills';
import Work from '../builder/left/sections/Work';
import Preview from './Preview';
import ModalEvents from '../../constants/ModalEvents';
import TopNavbar from '../dashboard/TopNavbar';

const WizardPanel = () => {
  const state = useSelector();
  const { id } = state;
  const [step, setStep] = useState(0);

  const options = [
    {
      id: 1,
      name: 'Personal Details',
    },
    {
      id: 2,
      name: 'Employment History',
    },
    {
      id: 3,
      name: 'Education',
    },
    {
      id: 4,
      name: 'Skills',
    },
    {
      id: 5,
      name: 'Professional Summary',
    },
    {
      id: 6,
      name: 'Preview Resume',
    },
  ];

  const steps = [
    {
      name: 'Personal Details',
      component: <Profile id="profile" isWizard="true" />,
    },
    {
      name: 'Employment History',
      component: (
        <Work id="work" event={ModalEvents.WORK_MODAL} isWizard="true" />
      ),
    },
    {
      name: 'Education',
      component: (
        <Education
          id="education"
          event={ModalEvents.EDUCATION_MODAL}
          isWizard="true"
        />
      ),
    },
    {
      name: 'Skills',
      component: (
        <Skills id="skills" event={ModalEvents.SKILL_MODAL} isWizard="true" />
      ),
    },
    {
      name: 'Professional Summary',
      component: <Objective id="objective" isWizard="true" />,
    },
    {
      name: 'Preview Resume',
      component: <Preview />,
    },
  ];

  const handleNextStep = () => {
    setStep((prev) => (prev < 5 ? prev + 1 : 5));
    console.log(step);
  };

  const handlePrevStep = () => {
    setStep((prev) => (prev > 0 ? prev - 1 : 0));
    console.log(step);
  };

  const handleDownload = () => {
    if (typeof window !== `undefined`) {
      window.location.href = `https://resumesmadehere.com/purchase/?resume_id=${id}`;
    }
  };

  const handleMoveStep = (selected) => {
    if (selected < options.length) {
      setStep(selected - 1);
    } else {
      handleDownload();
    }
  };

  const contentRenderer = () => (
    <div>
      Step {step + 1} of {options.length}
    </div>
  );

  return (
    <div>
      <div id="wizard_header" className={styles.wizard_header}>
        <TopNavbar />
        <Select
          options={options}
          values={[options[0]]}
          labelField="name"
          valueField="id"
          color="#1e6b5f"
          className={styles.select_step}
          onChange={(values) => handleMoveStep(values[0].id)}
          contentRenderer={contentRenderer}
        />
      </div>
      <div id="wizard_body" className={styles.container}>
        <Multistep activeStep={step} showNavigation={false} steps={steps} />
        {step !== 0 && step !== 5 && (
          <button
            id="download"
            className={styles.download_button}
            onClick={handleDownload}
          >
            Download PDF
            <FaRegFilePdf size={20} className={styles.pdf_icon} />
          </button>
        )}
      </div>
      <div id="wizard_footer" className={styles.wizard_footer}>
        <button
          id="prev"
          className={`${styles.wizard_footer_button} ${styles.prev_button}`}
          onClick={handlePrevStep}
        >
          Prev
        </button>
        <button
          id="next"
          className={styles.wizard_footer_button}
          onClick={handleNextStep}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default memo(WizardPanel);
