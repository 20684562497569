import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import * as styles from './wizard.module.css';
import { useDispatch } from '../../contexts/ResumeContext';
import DatabaseContext from '../../contexts/DatabaseContext';
import LoadingScreen from '../../components/router/LoadingScreen';
import WizardPanel from '../../components/wizard/WizardPanel';

const Wizard = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { getResume } = useContext(DatabaseContext);

  useEffect(() => {
    (async () => {
      const resume = await getResume(id);

      if (!resume) {
        navigate('/app/dashboard');
        toast.error(t('builder.toasts.doesNotExist'));
        return null;
      }

      dispatch({ type: 'set_data', payload: resume });
      return setLoading(false);
    })();
  }, [id]);

  return useMemo(() => {
    if (loading) {
      return <LoadingScreen />;
    }

    return (
      <div id="wizard" className={styles.container}>
        <WizardPanel />
      </div>
    );
  }, [loading]);
};

export default memo(Wizard);
